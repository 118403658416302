import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowDimensions from "../utils/windowSize";
import DropdownMenu from "../components/DropdownMenu";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { useEffect, useState } from "react";
import {
  PageNavDirection,
  PageNavNext,
  PageNavNextName,
  PageNavPrev,
  PageNavPrevName,
  PageNavigation,
} from "../components/styled-components/Globals/PageNavigation";
import {
  DeliverableIframe,
  IframeWrapper,
  NoScrollContainer,
} from "../components/styled-components/Globals/GlobalStyledComponents";

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 0;

  const titles = twoDLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function TwoDPage() {
  const { width } = useWindowDimensions();
  const [linkIndex, setLinkIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TWOD}>
      <Seo
        title="2D CAD Drawing Services | Robotic Imaging"
        description="Sample 2D Imaging services provided by Robotic Imaging."
      />

      <header className="site__header">
        <div className="site__header__button-container-tags">
          {twoDLinks.map((link, index) => (
            <button
              key={link.title}
              onClick={() => handleClick(link.title, index)}
              disabled={linkIndex === index}
              className={`site__header__button${
                linkIndex === index ? "-disabled" : ""
              }`}
            >
              {link.title}
            </button>
          ))}
        </div>

        <DropdownMenu
          index={linkIndex}
          setIndex={setLinkIndex}
          options={twoDLinks}
        />
      </header>

      <NoScrollContainer>
        <IframeWrapper>
          <DeliverableIframe
            title="two-d-drawing"
            src={linkIndex !== null ? twoDLinks[linkIndex].url : ""}
            allowFullScreen
          />
        </IframeWrapper>

        <PageNavigation>
          <PageNavPrev to="/3D" title="Previous page">
            <div>
              <ChevronLeftIcon />
              <PageNavDirection>Previous Page</PageNavDirection>
            </div>
            {width && width >= 650 ? (
              <PageNavPrevName>3D Imaging</PageNavPrevName>
            ) : null}
          </PageNavPrev>
          <PageNavNext to="/vr" title="Next page">
            <div>
              <PageNavDirection>Next Page</PageNavDirection>
              <ChevronRightIcon />
            </div>
            {width && width >= 650 ? (
              <PageNavNextName>Visual</PageNavNextName>
            ) : null}
          </PageNavNext>
        </PageNavigation>
      </NoScrollContainer>
    </SiteWrapper>
  );
}

export const twoDLinks = [
  {
    title: "LOD400 - Retail",
    url: "https://myhub.autodesk360.com/ue2a16417/g/shares/SHd38bfQT1fb47330c995a6051d96c0ee261?mode=embed",
  },
  {
    title: "LOD350 -   Facade",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c993988a6f829555044?mode=embed",
  },
  {
    title: "LOD300 - C-Store",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c993ac6f7be2ad173ec?mode=embed",
  },
  { title: "LOD300 - Building+Site", url: "https://a360.co/3UbwOZc" },
  { title: "LOD300 - Floor Plan", url: "https://a360.co/4azwXvu" },
  { title: "LOD300 - Store", url: "https://a360.co/4cS3gqY" },
  {
    title: "LOD200 - Floor Plan",
    url: "https://a360.co/4aLF73B",
  },
  {
    title: "LOD200 - Elevation",
    url: "https://a360.co/4cOKmBg",
  },
];
